<template>
  <div>
    <Nav @getCurrency="changeCurrency" @selectedCurrency="selectedCur" />

    <Tittle :type="'courses'">
      <h1 class="fadeInUp"><span></span>{{ details.title }}</h1>
    </Tittle>
    <div class="bg_color_1">
      <nav class="secondary_nav sticky_horizontal">
        <div class="container">
          <ul class="clearfix">
            <li>
              <a href="#description" class="active">{{
                details.secondTitle
              }}</a>
            </li>
            <!-- <li><a href="#lessons">Lessons</a></li>
						<li><a href="#reviews">Reviews</a></li> -->
          </ul>
        </div>
      </nav>
      <SkeletonLoader v-if="initLoading" />
      <div class="container margin_60_35" v-else>
        <div class="row">
          <div class="col-lg-8">
            <section id="description">
              <h2>Description</h2>
              <p
                v-if="details != ''"
                v-html="sanitizeDescription(details.description)"
              ></p>
              <h5>You will learn :</h5>
              <ul>
                <li v-for="what in whatYouLearn" :key="what">
                  <h6><i class="icon-check"></i> {{ what }}</h6>
                </li>
              </ul>
              <hr />
              <h2>Requirements</h2>
              <div class="row">
                <div class="col-lg-6">
                  <ul>
                    <li>
                      <i class="icon_target"></i> {{ details.requirement }}
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /row -->
            </section>
            <!-- /section -->

            <section id="lessons">
              <div class="intro_title">
                <h2>Lessons</h2>
                <ul>
                  <li>{{ lectures.length }} lessons</li>
                </ul>
              </div>
              <div id="accordion_lessons" role="tablist" class="add_bottom_45">
                <div class="card" v-for="lect in lectures" :key="lect">
                  <div class="card-header" role="tab" id="headingOne">
                    <h5 class="mb-0">
                      <a
                        data-toggle="collapse"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        ><i class="icon_folder-open"></i>
                        {{ lect.sectionTitle }}</a
                      >

                      <small
                        v-if="lect.description != null"
                        v-html="sanitizeDescription(lect.description)"
                      ></small>
                      <br />
                      <small
                        v-if="lect.externalLink != null"
                        v-html="sanitizeDescription(lect.externalLink)"
                      ></small>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    data-parent="#accordion_lessons"
                  >
                    <div class="card-body">
                      <div class="list_lessons">
                        <ul>
                          <li v-for="play in lect.videos" :key="play">
                            <a v-if="play.fileType == 'application/pdf'"
                              ><i class="icon_document"></i> {{ play.title }}</a
                            >
                            <a
                              v-if="
                                play.fileType != 'application/pdf' &&
                                lect.sectionTitle != 'Preview'
                              "
                              ><i class="arrow_triangle-right_alt"></i>
                              {{ play.title }}
                            </a>
                            <a
                              v-else
                              v-bind:data-toggle="
                                lect.sectionTitle == 'Preview'
                                  ? 'modal'
                                  : 'false'
                              "
                              @click="
                                switchFile(
                                  play.uri,
                                  play.embedLink,
                                  play.fileType
                                )
                              "
                              data-target="#exampleModalCenter"
                              style="cursor: pointer"
                              ><i class="arrow_triangle-right_alt"></i>
                              {{ play.title }}</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /card -->
              </div>
              <!-- /accordion -->
            </section>
            <!-- /section -->

            <RatingsDetails :id="$route.params.id" :type="'course'" />
          </div>
          <!-- /col -->

          <aside class="col-lg-4" id="sidebar">
            <div class="box_detail">
              <figure>
                <a
                  class="video"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  ><i class="arrow_triangle-right"></i
                  ><CourseImage
                    :name="details.thumbnailFile"
                    style="height: 200px; width: 100%"
                  /><span>View course preview</span
                  ><!--<img v-bind:src="backEnd+'public/courseImage/'+courseId" alt="" class="img-fluid">--><!--<span>View course preview</span>--></a
                >
              </figure>
              <div class="price" v-if="price != 0">
                <span v-if="rate">
                  <span v-if="selectedCurrency === 'GBP'">
                    £{{
                      (price * parseFloat(rate))
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </span>
                  <span v-if="selectedCurrency === 'NGN'">
                    ₦{{
                      (price * parseFloat(rate))
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </span>
                  <span v-if="selectedCurrency === 'USD'">
                    ${{
                      (price * parseFloat(rate))
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </span>
                  <span class="original_price">
                    <br />
                    <Discount
                      :rate="rate"
                      :selectedCurrency="selectedCurrency"
                      :id="details._id"
                      :price="price"
                      :discount="details.discountPercent"
                      v-if="this.details != ''"
                    />
                  </span>
                </span>
                <span v-else>
                  ₦{{ price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}<span class="original_price"
                    ><br /><Discount
                      :id="details._id"
                      :price="price"
                      :discount="details.discountPercent"
                      v-if="this.details != ''"
                    />
                  </span>
                </span>
              </div>
              <div class="price" v-else>FREE</div>
              <a
                :href="'/Dcoursedetail/' + details._id"
                class="btn_1 full-width"
                >Purchase</a
              >
              <a
                :href="'/Dcoursedetail/' + details._id"
                class="btn_1 full-width outline"
                ><i class="icon_heart"></i>Add to wishlist</a
              >
              <div id="list_feat">
                <h3>What's includes</h3>
                <ul>
                  <li><i class="icon_archive_alt"></i>Lesson archive</li>
                  <li><i class="icon_mobile"></i>Mobile support</li>
                  <li><i class="icon_chat_alt"></i>Tutor chat</li>
                  <li><i class="icon_document_alt"></i>Course certificate</li>
                  <li><i class="icon_lock-open_alt"></i>Live access</li>
                  <li><i class="icon_clock_alt"></i>Self pace</li>
                </ul>
                <br />
                <hr />
                <div v-if="details != ''">
                  <h5>Course by :</h5>
                  <p>
                    {{ details.user[0].firstName }}
                    {{ details.user[0].LastName }}
                  </p>
                </div>
              </div>
            </div>
          </aside>
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->
    </div>
    <!-- /bg_color_1 -->
    <PreviewModal
      :fileName="prevewfileName"
      :fileType="prevewfileType"
      v-if="prevewfileName != '' && prevewfileType != ''"
    />
    <Footer />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Nav from "@/components/site/Nav.vue";
import Tittle from "@/components/site/Tittle.vue";
import RatingsDetails from "@/components/site/RatingsDetails.vue";
import Footer from "@/components/site/Footer.vue";
import CourseImage from "@/components/CourseImage";
import Discount from "@/components/Discount";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
import PreviewModal from "@/components/PreviewModal.vue";
export default {
  name: "Coursedetails",
  data() {
    return {
      initLoading: false,
      details: "",
      whatYouLearn: "",
      lectures: "",
      courseId: "",
      price: "",
      alreadyReviewed: true,
      reviews: "",
      stars: "",
      one: "",
      two: "",
      three: "",
      four: "",
      five: "",
      avrating: "",
      allLikes: "",
      coursePreview: "",
      prevewfileName: "",
      prevewfileType: "",
      url: "",
      rate: null,
      selectedCurrency: "",
    };
  },
  computed: {
    ...mapGetters(["backEnd"]),
  },
  components: {
    Nav,
    PreviewModal,
    Tittle,
    RatingsDetails,
    Footer,
    CourseImage,
    Discount,
    SkeletonLoader,
  },
  methods: {
    ...mapActions([
      "fetch_public_course_details__public",
      "fetch_viewCourseMinimal__public",
      "generate_V4ReadSignedUrl_public",
    ]),
    ...mapActions(["fetchReviewedPublic"]),
    switchFile(uri, embedLink, type) {
      this.prevewfileName = "";
      if (type == "embedLink") {
        this.prevewfileName = embedLink;
        this.prevewfileType = type;
      } else {
        this.prevewfileName = uri;
        this.prevewfileType = type;
      }
    },
    checkStar(star) {
      let total = 0;
      let answer = 0;
      if (this.stars.length == 0) {
        answer = 0;
      } else {
        for (let i = 0; i < this.stars.length; i++) {
          const element = this.stars[i];
          if (element.star == star) {
            total++;
          }
        }

        answer = (total / this.stars.length) * 100;
      }
      return answer.toFixed(1);
    },
    checkAveageStar() {
      let total = 0;
      let answer = 0;
      if (this.stars.length == 0) {
        answer = 0;
      } else {
        for (let i = 0; i < this.stars.length; i++) {
          const element = this.stars[i];
          total = element.star + total;
        }
        answer = total / this.stars.length;
      }
      return answer.toFixed(1);
    },
    countlikes() {
      let total = { like: 0, dislike: 0 };
      for (let i = 0; i < this.stars.length; i++) {
        const element = this.stars[i];
        if (element.like == 1) {
          total.like++;
        } else {
          total.dislike++;
        }
      }
      return total;
    },
    regexLink(link) {
      var expression = /https:\/\/[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/;
      var regex = new RegExp(expression);
      if (link != "") {
        if (link.match(regex)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    sanitizeDescription(text) {
      const words = text.split(" ");
      let backEndLink = "/Dcoursedetail/" + this.details._id;
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (this.regexLink(word)) {
          words[i] =
            '<a href="' +
            backEndLink +
            '" target="_blank" rel="noopener noreferrer">Click here to access link</a>';
        }
      }
      var newDescription = "";
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        newDescription = newDescription + " " + word;
      }
      return newDescription;
    },
    checkCoursePreview() {
      for (let i = 0; i < this.lectures.length; i++) {
        const element = this.lectures[i];
        if (element.sectionTitle == "Preview") {
          for (let j = 0; j < element.videos.length; j++) {
            const elementj = element.videos[j];
            if (elementj.isPreview) {
              this.coursePreview = elementj;
              this.prevewfileType = this.coursePreview.fileType;
              if (this.coursePreview.fileType != "embedLink") {
                let fileName = this.coursePreview.uri + "";
                this.prevewfileName = fileName;
              } else {
                this.prevewfileName = this.coursePreview.embedLink + "";
              }
              break;
            }
          }
          if (this.prevewfileName == "" && element.videos.length != 0) {
            this.coursePreview = element.videos[0];
            if (this.coursePreview.fileType != "application/pdf") {
              this.prevewfileType = this.coursePreview.fileType;
              if (this.coursePreview.fileType != "embedLink") {
                this.prevewfileName = this.coursePreview.uri + "";
              } else {
                this.prevewfileName = this.coursePreview.embedLink + "";
              }
            }
          }
        }
      }
    },
    changeCurrency(rate) {
      this.rate = rate;
    },
    selectedCur(cur) {
      this.selectedCurrency = cur;
    },
  },
  mounted() {
    let course_id = this.$route.params.id;
    this.courseId = course_id;
    let object = {
      courseId: course_id,
    };
    this.initLoading = true;
    this.fetch_viewCourseMinimal__public(object).then((res) => {
      this.details = res.data.course;
      let what = this.details.whatYouLearn + "";
      this.whatYouLearn = what.split(",");
      this.price = res.data.course.price.$numberDecimal;
    });
    this.fetch_public_course_details__public(object).then((res) => {
      this.initLoading = false;
      this.lectures = res.data.modules;
      this.checkCoursePreview();
    });

    // let reviewObject= {
    // 	id : this.courseId,
    // 	type : 'course'
    // }
    // this.fetchReviewedPublic(reviewObject)
    // .then(res => {
    // 	this.alreadyReviewed = res.data.alreadyReviewed
    // 	this.reviews = res.data.Reviews
    // 	this.stars = res.data.stars
    // 	this.one = this.checkStar(1)
    // 	this.two = this.checkStar(2)
    // 	this.three = this.checkStar(3)
    // 	this.four = this.checkStar(4)
    // 	this.five = this.checkStar(5)
    // 	this.avrating = this.checkAveageStar()
    // 	this.allLikes = this.countlikes()
    // })
  },
};
</script>

<style>
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/style.css");
@import url("./assets/css/vendors.css");
@import url("./assets/css/icon_fonts/css/all_icons.min.css");
@import url("./assets/css/skins/square/grey.css");
@import url("./assets/css/wizard.css");
@import url("./assets/css/blog.css");
@import url("./assets/css/tables.css");
@import url("./assets/css/custom.css");
</style>